// Background //

.background-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  object-fit: cover;
  background-image: url(../../public/images/back.webp);
  background-size: cover;
  background-repeat: none;
  background-position: center;
}

// Headers //

.background-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  h1 {
    font-size: 3rem;
    width: 90%;
    color: #ffffff;
    height: 3rem;
    text-align: center;
  }
  h3 {
    font-size: 1.3rem;
    color: #ffffff;
    height: 3rem;
    text-align: center;
  }
}
// H1 exception //

@media only screen and (min-width:366px){
  .background-container{
    h1{
      font-size: 4.5rem;
      height: 5rem;
    }
    h3{
      font-size: 2rem;
    }
  }
}

// Section, Image, Headers //



.first-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  .image-header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    .first-image-container {
      height: 50rem;
      width: 100%;
      max-width: auto;
      overflow: hidden;
      display: flex;
      justify-content: center;
      border-radius: 2rem;
      margin: 3rem 0 1.5rem 0;
      box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
      .image-fc {
        height: 120%;
        width: 100%;
        object-fit: cover;
      }
    }
    h1 {
      font-size: 5rem;
      font-family: "Montserrat", sans-serif;
      font-optical-sizing: auto;
      font-weight: 600;
      font-style: normal;
      text-align: center;
      max-width: 80%;
    }
    .first-p {
      font-size: 2.4rem;
      font-family: "Lato", sans-serif;
      font-weight: 800;
      font-style: normal;
      margin-bottom: 2rem;
      line-height: 3.5rem;
      text-align: center;
      max-width: 100%;
    }
  }
  // Info Containers //

  .main-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .info-container,
  .snd-info-container,
  .contact-info-container {
    height: fit-content;
    width: 80%;
    background: #ffffff;
    box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.13);
    border-radius: 2rem;
    padding: 2rem;
    font-family: "Lato", sans-serif;
    font-style: normal;
    margin-bottom: 2rem;
    h2 {
      font-size: 3.4rem;
      font-weight: 800;
      text-shadow: -1px 4px 12px rgba(0, 0, 0, 0.2);
      margin-bottom: 1rem;
    }
  }
  .info-container {
    h3 {
      font-size: 2.5rem;
      font-weight: 800;
      color: #aaa6a6;
      margin-bottom: 0.3rem;
    }
    p {
      font-size: 2.3rem;
      font-weight: 800;
      margin-bottom: 1rem;
      line-height: 3rem;
    }
  }

  .snd-info-container {
    h2 {
      margin-bottom: 2rem;
    }
    h3 {
      font-size: 2.5rem;
      font-weight: 800;
    }
    .info-container-grid {
      display: grid;
      grid-template-columns: 4rem 5fr;
      row-gap: 2rem;
    }
    img {
      margin-right: 1.5rem;
      height: 3rem;
      width: 3rem;
    }
  }

  .contact-info-container {
    div {
      display: flex;
      margin: 1.3rem 0 0rem 0;
      img {
        height: 2.5rem;
        width: 2.5rem;
        margin-right: 1rem;
      }
      h3 {
        font-size: 2rem;
      }
    }
  }
}

// First Image Section
.second-section {
  display: flex;
  justify-content: center;
  .image-section {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0.6rem;
    grid-row-gap: 0.6rem;
    .fst-image {
      grid-area: 1 / 1 / 4 / 3;
      height: 18rem;
      background: url(../../public/images/DSC_97742.webp) center/150%;
      object-fit: scale-down;
    }
    .snd-image {
      grid-area: 5 / 3 / 7 / 5;
      height: 12rem;
      background: url(../../public/images/DSC_9774.webp) center/150%;
      object-fit: scale-down;
    }
    .black-fst,
    .black-snd {
      background: #141414;
    }
    .black-fst {
      grid-area: 1 / 3 / 5 / 5;
    }
    .black-snd {
      grid-area: 4 / 1 / 7 / 3;
    }
  }
}

// Third Section //

.third-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
  .service-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    font-size: 4rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 2rem;
  }

  .service-info-container {
    background: #ffffff;
    height: fit-content;
    width: 80%;
    box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.13);
    font-family: "Lato", sans-serif;
    font-style: normal;
    padding: 3rem;
    border-radius: 2rem;
    margin-bottom: 3rem;
    h2 {
      font-size: 2.5rem;
      font-weight: 800;
      text-shadow: -1px 4px 12px rgba(0, 0, 0, 0.2);
      margin-bottom: 2rem;
    }
    p {
      font-size: 2rem;
      font-weight: 800;
    }
    .button-pricing,
    a {
      margin-top: 2rem;
      background: #141414;
      font-size: 2rem;
      color: #ffffff;
      border: none;
      padding: 1.2rem 1.5rem;
      border-radius: 4rem;
      font-weight: 700;
      text-decoration: none;
    }
  }
  .service-img {
    height: 50%;
    width: 100%;
    margin-bottom: 2rem;
  }
}

// Fourth Section //

.fourth-section {
  display: flex;
  justify-content: center;
  .image-section {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0.6rem;
    grid-row-gap: 0.6rem;
    .fst-image {
      grid-area: 4 / 1 / 6 / 3;
      background: #a52828;
      object-fit: scale-down;
      background: url(../../public/images/DSC_9744.webp) center/150%;
      background-position: 50% 60%;
    }
    .snd-image {
      grid-area: 1 / 4 / 4 / 6;
      height: 18rem;
      object-fit: scale-down;
      background: #141414;
      background: url(../../public/images/DSC_9744.webp) center/150%;
      background-position: 50% 30%;
    }
    .black-fst,
    .black-snd {
      background: #141414;
    }
    .black-fst {
      grid-area: 1 / 1 / 4 / 4;
    }
    .black-snd {
      grid-area: 4 / 3 / 6 / 6;
    }
  }
}

// Page Not Found //

.page-not-found {
  height: 100vh;
  display: grid;
  place-content: center;
}

// Responsive Design Tablet (700x1000) //

@media only screen and (min-width: 450px) {
  .first-section {
    .image-header-container{
      .first-image-container {
        width: auto;
        .image-fc {
          height: 70rem;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// Responsive Design Desktop (1366x768)

@media only screen and (min-width: 1100px) {
  .background-container{
    h1{
      font-size: 10rem;
      font-weight: 500;
      height: 10rem;
    }
    h3{
      font-size: 3rem;
      font-weight: 500;
    }
  }
  .first-section {
    margin-top: 5rem;
    .image-header-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(7, 1fr);
      grid-column-gap: 5rem;
      grid-row-gap: 1rem;
      width: 70%;
      .first-image-container {
        grid-area: 1 / 1 / 9 / 4;
        height: 55rem;
        overflow: hidden;
        display: flex;
        align-items: start;
        .image-fc {
          width: 100%;
          object-fit: cover;
          display: flex;
        }
      }
      h1 {
        font-size: 5rem;
        width: auto;
        grid-area: 2 / 4 / 3 / 6;
      }
      .first-p {
        grid-area: 3 / 4 / 6 / 8;
        text-align: left;
        width: auto;
        font-weight: 600;
        font-size: 3rem;
        align-self: flex-start;
      }
    }
    .main-info-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
      align-items: normal;
      margin-top: 2rem;
      height: 70rem;
      width: 70%;
      .info-container,
      .snd-info-container,
      .contact-info-container {
        margin-bottom: 0rem;
      }
      .info-container {
        grid-row: 1 / 1;
        grid-column: 1 / 4;
        width: auto;
        object-fit: cover;
        h3 {
          font-size: 2rem;
        }
        p {
          font-size: 1.6rem;
          margin-bottom: 0.1rem;
          line-height: 2.5rem;
        }
      }
      .fst-grid {
        width: auto;
        grid-row: 1 / 3;
        grid-column: 4 / 8;
        object-fit: cover;
      }
      .snd-grid {
        width: auto;
        grid-row: 2 / 5;
        grid-column: 1 / 4;
      }
      .contact-info-container {
        width: auto;
        margin-top: 2rem;
        grid-row: 3 / 5;
        grid-column: 4 / 8;
      }
    }
  }
  .second-section {
    .image-section {
      height: 60rem;
      width: 70%;
      .fst-image {
        height: auto;
      }
      .snd-image {
        height: auto;
      }
    }
  }
  .third-section {
    .service-main-container {
      width: 70%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(7, 1fr);
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
      .sic-grid-fst {
        grid-row: 1 / 8;
        grid-column: 1 / 4;
        width: auto;
        height: auto;
      }
      .sic-grid-snd {
        grid-row: 1 / 5;
        grid-column: 4 / 6;
        height: auto;
        width: auto;
      }
      .sic-grid-trd {
        grid-row: 4 / 8;
        grid-column: 4 / 6;
        height: auto;
        width: auto;
      }
    }
  }
  .fourth-section{
    .image-section{
      height: 60rem;
      width: 70%;
      .fst-image{
        height: auto;
        background-position: 50% 70%;
      }
      .snd-image{
        height: auto;
        background-position: 50% 45%;
      }
    }
  }
}
