// Footer //

.footer{
    margin-top: 1rem;
    background: #141414;
    height: 60rem;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    font-family: "Lato", sans-serif;
    font-style: normal;
    .contact-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h1{
        font-size: 4rem;
        margin: 1rem 0 2rem 0;
        font-weight: 800;
    }
    .grid-contact-container{
        display: grid;
        grid-template-columns: 4rem 5fr;
        align-items: center;
        row-gap: 2rem;
        h2{
            font-size: 1.8rem;
            word-spacing: 0.3rem;
            margin-left: 0.2rem;
        }
    }
    .index-container{
        margin-top: 1.4rem;
    }
    ul{
        list-style: none;
        li{
            text-align: center;
            margin-bottom: 2rem;
            a{
                text-decoration: none;
                font-size: 3rem;
                color: #fff;
                font-weight: 700;
            }
        }
    }
}

// Responsive Design Desktop (1366x768)

@media only screen and (min-width: 1366px){
    .footer{
        flex-direction: row;
        justify-content: space-around;
        height: auto;
        .grid-contact-container{
            h2{
                font-size: 2.5rem;
            }
        }
    }
}