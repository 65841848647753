.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  .pricing-container{
    width: 90%;
  }
}

// Low resolution exception //

@media only screen and (min-width: 360px){
  .pricing-section{
    .pricing-container{
      width: auto;
    }
  }
}

.fst-plan {
  margin-top: 11rem;
  
}

.fst-plan,
.snd-plan,
.prm-plan {
  height: fit-content;
  width: 100%;
  min-width: fit-content;
  background: #fff;
  border-radius: 4rem;
  box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
  font-family: "Lato", sans-serif;
  font-style: normal;
  padding: 3rem;
  margin-bottom: 3rem;
  .prices{
    display: flex;
    column-gap: 1rem;
    align-items: center;
    h4{
      font-size: 2rem;
      text-decoration: line-through;
      color: #525252;
    }
  }
  h1 {
    font-size: 4rem;
    font-weight: 800;
    text-shadow: -1px 4px 12px rgba(0, 0, 0, 0.2);
  }
  .snd-header {
    font-size: 2.4rem;
    margin: 1rem 0;
    font-weight: 800;
    color: #827c7c;
  }
  h2 {
    font-size: 3.5rem;
    font-weight: 800;
    margin: 0 0 1rem 1rem;
  }
  .features {
    display: grid;
    grid-template-columns: 3rem 5fr;
    align-items: center;
    row-gap: 1rem;
    h3 {
      font-size: 2.8rem;
      font-weight: 800;
      min-width: 120%;
    }
  }
  .contact-me {
    display: flex;
    justify-content: center;
    button,
    .nmc-contact {
      background: #141414;
      color: #fff;
      border: none;
      padding: 1.4rem 2rem;
      font-size: 2.5rem;
      border-radius: 10rem;
      margin-top: 2rem;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.prm-plan {
  h1 {
    background-image: linear-gradient(to right, #ec6262, #db59c8, #4a3ee5);
    -webkit-background-clip: text;
    color: transparent;
  }
  .contact-me {
    button {
      a {
        font-size: 2.5rem;
        padding: 1.4rem 2rem;
        color: #fff;
        font-weight: 700;
        text-decoration: none;
      }
      background-image: linear-gradient(to right, #ec6262, #db59c8, #4a3ee5);
    }
  }
}

// Responsive Design Tablet (700x1000) //

@media only screen and (min-width: 700px) {
  .fst-plan,
  .snd-plan,
  .prm-plan {
    width: 60%;
    max-width: 50rem;
    h2 {
      font-size: 4.5rem;
      font-weight: 600;
    }
  }
}

// Responsive Design Desktop (1366x768)

@media only screen and (min-width: 1100px) {
  .pricing-section {
    .pricing-container{
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 4rem;
    }
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 4rem;
    height: 100vh;
  }
  .fst-plan,
  .snd-plan,
  .prm-plan {
    margin-top: 7rem;
    max-width: 50rem;
  }

  .fst-plan{
    margin-left: 3rem;
  }
  .prm-plan{
    margin-right: 3rem;
  }
}
