// Contact Form //

.form-section{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
}
.contact-form{
    margin-top: 13rem;
    width: 80%;
    height: fit-content;
    background: #fff;
    box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.4rem;
    input , textarea{
        height: 6rem;
        border: none;
        border-bottom: 3px #ccc solid;
        font-size: 2rem;
        padding: 0 1rem;
        width: 90%;
        resize: none;
        font-family: sans-serif;
        font-style: normal;
    }
    textarea{
        padding: 1rem;
    }
    input:focus, textarea:focus{
        background-color: #e7e7e7;
        outline: none;
        border-radius: 0.3rem;
    }
    .select-subject{
        height: 6rem;
        width: 90%;
        border: none;
        border-bottom: 3px #ccc solid;
        font-size: 2rem;
        padding: 0 1rem;
    }
    .select-subject:focus, textarea:focus{
        background-color: #e7e7e7;
        outline: none;
    }
    #submit{
        height: 5rem;
        margin: 2rem 0 1rem 0;
        width: auto;
        font-size: 2rem;
        padding: 1rem;
        border-radius: 5rem;
        background-image: linear-gradient(
            to right,
            #EC6262,
            #DB59C8,
            #4A3EE5
          ); 
          border: none;
          color: #fff;
    }
}

// Sended //

.sended-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1{
        font-size: 5rem;
        font-family: "Lato", sans-serif;
        font-style: normal;
    }
    #ty-header{
        background-image: linear-gradient(
            to right,
            #EC6262,
            #DB59C8,
            #4A3EE5
          );
          -webkit-background-clip: text;
          color: transparent;
    }
}

// Form Validation //

.form-validate{
    font-size: 1.5rem;
    font-family: "Lato", sans-serif;
    font-style: normal;
    color: #EC6262;
    font-weight: 700;
}

// Responsive Design Desktop (1366x768)

@media only screen and (min-width: 1100px) {
    .contact-form{
        width: 50%;
        #submit{
            width: auto;
            padding: 0 3rem;
            cursor: pointer;
        }
    }
}