// NavBar //

.menu-options-desktop{
 display: none;
}

.nav-bar {
  background: #ffffff;
  position: fixed;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 6rem;
  place-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.06);
  z-index: 2;
  h2{
    display: hidden;
    opacity: 0;
  }
}

// Menu SVG//
.nav-bar_menu_image {
  height: 4rem;
  margin: 0 0 0 1.4rem;
  grid-area: 2 / 1 / 3 / 2;
}

// NavBar Header //
.nav-bar_header {
  font-size: 2.5rem;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  grid-area: 2 / 3 / 3 / 4;
}

// Menu Clicked //

.menu-clicked {
  background: #f1f1f1;
  height: 200vh;
  width: 80%;
  position: fixed;
  z-index: 1;
  animation: display-menu 1s ease;
}

@keyframes display-menu {
  from {
    left: -80%;
  }
  to {
    left: 0;
  }
}

.menu-not-clicked {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

// Menu Options //

.menu-options {
  position: relative;
  top: 6rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  * {
    margin: 2rem;
  }
  .close-menu {
    height: 3rem;
    align-self: flex-end;
  }
  a {
    font-size: 4rem;
    text-decoration: none;
    color: #141414;
  }
}

// Responsive Design Tablet (700x1000) //

@media only screen and (min-width: 700px) {
  .nav-bar {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 8rem;
  }
  .nav-bar_menu_image {
    grid-area: 2 / 1 / 3 / 2;
  }
  .nav-bar_header {
    grid-area: 2 / 3 / 3 / 4;
  }
  .menu-options{
    top: 8rem;
  }
}

// Responsive Design Desktop (1366x768)

@media only screen and (min-width: 1100px){
  .nav-bar{
    height: 6rem;
    display: flex;
    justify-content: space-between;
    .menu-options-desktop{
      display: flex;
      gap: 2rem;
      margin-right: 5%;
      a{
        font-size: 2.4rem;
        color: #141414;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        border-top: 0.4rem transparent solid;
        border-bottom: 0.2rem transparent solid;
      }
     }
     h2{
      font-size: 2rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      margin-right: 2rem;
      display: block;
      opacity: 1;
    }
  }
  .nav-bar_header {
    grid-area: 3 / 1 / 4 / 2;
    font-weight: 500;
    margin-left: 2rem;
    font-size: 3rem;
  }
  .nav-bar_menu_image {
    display: none;
  opacity: 0;
  }
}